import { css } from 'astroturf';
import React, { useState } from 'react';

const styles = css`
  .newsletter {
    border: 1px solid #4ca2cd;
    background: #fff;
    border-radius: 40px;
    line-height: 2rem;
    color: #000;
    display: flex;
    justify-content: space-between;
    padding: 2px;
    min-width: 250px;
    max-width: 400px;
    margin: 0.5rem 0 0;

    & input {
      width: calc(100% - 104px);
      margin: 0;
      padding: 0 1rem;
      border: 0;
      height: 2rem;
      line-height: 2rem;
      background: transparent;
      font-size: 1rem;

      border-radius:  40px 0 0 40px;
    }

    & button {
      border: 0;
      border-radius:  40px ;
      padding: 0 1rem;
      line-height: 2rem;
      background: #107db5;
      font-weight: 600;
      color: #fff;
      transition: 0.25s ease-in-out background;

      &:hocus {
        background: #67b26f;
      }
    }
  }
  .errormsg {
    border-radius: 0.5rem;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0px 2px 4px rgba(0, 40, 100, 0.15) inset;
    padding: 1rem;
    margin-top: 1rem;
    font-size: 0.9rem;
    font-style: italic;

    & a {
      display: block;
      color: inherit;
      text-decoration: underline;
    }
  }
  .successmsg {
    border-radius: 0.5rem;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0px 2px 4px rgba(0, 40, 100, 0.15) inset;
    padding: 1rem;
  }

  .button.spinner {
    color: transparent;
    pointer-events: none;
    position: relative;

    &:after {
      animation: spinAround 500ms infinite linear;
      border: 2px solid rgba(255, 255, 255, 0.8);
      border-radius: 290486px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: ' ';
      display: block;
      width: 1em;
      height: 1em;
      left: calc(50% - (1em / 2));
      top: calc(50% - (1em / 2));
      position: absolute !important;
    }
  }

  @keyframes spinAround {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

export default () => {
  const [messageState, setMessageState] = useState('unsent');
  const [showFormMessage, setShowFormMessage] = useState(false);
  const [formMessage, setFormMessage] = useState('');
  const [formMessageType, setFormMessageType] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.elements[0].value;
    const link = 'https://mails.firstversionist.com/subscribe';
    const data = new URLSearchParams(new FormData(e.target));
    setMessageState('sending');

    const result = await fetch(link, {
      method: 'POST',
      body: data,
    });

    setMessageState('sent');
    setShowFormMessage(true);
    setFormMessageType(result.ok ? 'success' : 'error');
    setFormMessage(
      result.ok
        ? "Thanks for subscribing! We've sent you a confirmation email."
        : 'something went wrong. Please try again later.'
    );
    if (typeof window !== 'undefined' && window.ga) {
      window.ga('send', 'event', 'Newsletter', 'Newsletter Signup');
    }
  };

  const resetForm = () => {
    setShowFormMessage(false);
  };

  return (
    <>
      {showFormMessage && (
        <div className={formMessageType === 'error' ? styles.errormsg : styles.successmsg}>
          <div dangerouslySetInnerHTML={{ __html: formMessage }} />
        </div>
      )}

      <form className={styles.newsletter} onSubmit={handleSubmit}>
        <label style={{ opacity: 0, pointerEvents: 'none', position: 'absolute' }} htmlFor="email">
          Email
        </label>
        <input
          placeholder="Your e-mail"
          type="email"
          defaultValue=""
          name="email"
          id="email"
          required
          autoComplete="email"
          className="required email"
          onFocus={resetForm}
        />
        <div style={{ display: 'none' }}>
          <label htmlFor="hp">HP</label>
          <br />
          <input type="text" name="hp" id="hp" />
        </div>
        <input type="hidden" name="list" value="rJWYXoAMA758oOexwUZkeg" />
        <input type="hidden" name="subform" value="yes" />
        <button
          type="submit"
          value="Subscribe"
          name="subscribe"
          className={['button', messageState === 'sending' ? styles.spinner : ''].join(' ')}
        >
          Subscribe
        </button>
      </form>
    </>
  );
};
